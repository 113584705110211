import React, { useState, useEffect } from 'react'
import WaifuCard from './WaifuCardItem';
import TractorCard from './TractorCardItem';
import MiniWaifuCard from './miniWaifuCard';
import HarvestingItem from './HarvestingItem';

import cottonIcon from "../../assets/img/claimIcons/cottonIcon.svg";
import woolIcon from "../../assets/img/claimIcons/woolIcon.svg";
import leatherIcon from "../../assets/img/claimIcons/leatherIcon.svg";
import leaveIcon from "../../assets/img/claimIcons/leaveIcon.svg";
import flaxIcon from "../../assets/img/claimIcons/flaxIcon.svg";
import silkIcon from "../../assets/img/claimIcons/silkIcon.svg";


export default function PaginationComponent({ openFilter, activeIcon, buyTractor, buyWaifu, paginationCant, listItems, item, setModal, setModalNft, setModalNftBurn, balance }) {

    const [pagination, setPagination] = useState(paginationCant)

    const [arrayIcon] = useState([cottonIcon, woolIcon, leatherIcon, leaveIcon, flaxIcon, silkIcon])
    const IsNextPageable = () => ((listItems.length > pagination));
    const IsBackPageable = () => (listItems.length / paginationCant >= 1);

    const onClickNext = () => {
        if (pagination < listItems.length) {
            setPagination(pagination + paginationCant);
        }
    };

    const onClickBack = () => {
        if (pagination > paginationCant) {
            setPagination(pagination - paginationCant);
        }
    };


    useEffect(() => { setPagination(paginationCant) }, [listItems])



    const Page = () => {


        let page = [];

        for (let i = 0; i < (listItems.length / paginationCant); i++) {
            page.push(i);
        }

        const onClickPage = (index) => {
            index = (index + 1) * paginationCant;
            setPagination(index);

        };

        return (<>

            {page.map((pag, index) => {
                if (
                    (index + 1 >= pagination / paginationCant - 1 &&
                        index + 1 <= pagination / paginationCant) ||
                    (index + 1 <= pagination / paginationCant + 1 &&
                        index + 1 >= pagination / paginationCant)
                ) {

                    return (<button style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }} className={`${pagination / paginationCant === index + 1 ? "bg-[#AA2E74] text-white" : "bg-[#F0C1DC]"} mx-1  rounded-md w-9 h-9  font-semibold cursor-pointer active:scale-90`} onClick={() => { onClickPage(index) }}> {index + 1}  </button>)
                }
            })}
        </>)

    }

    const renderComponent = (element) => {

        switch (item) {
            case "waifu": return <WaifuCard icon={arrayIcon} element={element} />
            case "waifuMint": return <WaifuCard icon={arrayIcon} mint={true} element={element} inventory={true} />
            case "waifuMarket": return <WaifuCard openFilter={openFilter} activeIcon={activeIcon} icon={arrayIcon} buyWaifu={buyWaifu} element={element} market={true} purchasable={balance > parseInt(element.sellPrice) / parseInt(1 + '0'.repeat(18)) ? true : false} />
            case "waifuInventory": return <WaifuCard icon={arrayIcon} setModalNft={setModalNft} setModalNftBurn={setModalNftBurn} element={element} inventory={true} />
            case "tractorMarket": return <TractorCard element={element} market={true} buyTractor={buyTractor} purchasable={balance > parseInt(element.sellPrice) / parseInt(1 + '0'.repeat(18)) ? true : false} />
            case "tractor": return <TractorCard element={element} />
            case "tractorInventory": return <TractorCard setModalNft={setModalNft} setModalNftBurn={setModalNftBurn} element={element} inventory={true} />
            case "harvesting": return <HarvestingItem setModal={setModal} />
            case "licenseHarvest": return <MiniWaifuCard element={element} />
            default: break;
        }
    }

    return (
        <>



            <div className="flex flex-row flex-wrap gap-4 justify-around ">
                {listItems && listItems.map((element, i) => (i < pagination && i >= pagination - paginationCant) && renderComponent(element))}
            </div>
            <div className="flex justify-center pt-10 text-center">
                <button className={`font-semibold pr-8 text-xl ${IsBackPageable() ? 'text-primaryDark hover:text-primary' : 'text-bordergray'}`} onClick={() => (IsBackPageable() && onClickBack())}>Previous</button> <Page /> <button className={`font-semibold pl-8 text-xl ${IsNextPageable() ? 'text-primaryDark hover:text-primary' : 'text-bordergray'}`} onClick={() => (IsNextPageable() && onClickNext())}>Next</button>
            </div>
        </>
    )
}
