import React, { useState, useEffect, useContext } from "react";

import Background_time from "../elements/background_time";
import GetImageAdn from "../elements/getImageAdn";

import start1 from "../../assets/vector/startWaifu1.svg"
import start2 from "../../assets/vector/startWaifu2.svg"
import start3 from "../../assets/vector/startWaifu3.svg"
import start4 from "../../assets/vector/startWaifu4.svg"
import start5 from "../../assets/vector/startWaifu5.svg"
import license_icon from "../../assets/img/licenseIcon.svg"
import UserContext from "../../context/userContext/UserContext";

export default function MiniWaifu({ element, icon, select, setSelect, harvestingMaterial, onClickSelectedWaifu }) {

    const [licenceTime, setLicenceTime] = useState(null);
    const { contract, account, chainId } = useContext(UserContext)

    const startValue = () => {

        if (!element) return


        let value;

        if (harvestingMaterial || harvestingMaterial === 0) value = element.waifuPowers[harvestingMaterial]
        else value = Math.max(...element.waifuPowers);



        if (harvestingMaterial || harvestingMaterial === 0) value = element.waifuPowers[harvestingMaterial]

        if (value <= 49) return 0
        else if (value <= 99) return 1
        else if (value <= 149) return 2
        else if (value <= 199) return 3
        else if (value <= 250) return 4

    }

    useEffect(() => {

        contract.ContractDwMetastore.methods.licenses(element.id).call({ from: account }).then(result => setLicenceTime(result))

    }, [])
    const startArray = [start1, start2, start3, start4, start5];
    const bg_time = Background_time.Waifu();

    const [waifuPower, setWaifuPower] = useState(0);
    const [waifuIcon, setWaifuIcon] = useState(null);
    const [cardSelect, setCardSelect] = useState('SELECT');

    useEffect(() => { setCardSelect(select === 'ALL' ? "READY" : "SELECT") }, [select])
    useEffect(() => {
        if (element && element.waifuPowers) {

            if (harvestingMaterial || harvestingMaterial === 0) {
                setWaifuIcon(icon)
                return setWaifuPower(element.waifuPowers[harvestingMaterial]);
            }

            let maxWaifuPower = Math.max(...element.waifuPowers)
            let tempAdn = parseInt(element.adn)

            let indexWaifuPower;
            if (tempAdn < 6) indexWaifuPower = tempAdn - 1
            else indexWaifuPower = element.waifuPowers.indexOf(maxWaifuPower.toString())


            setWaifuIcon(icon[indexWaifuPower])
            setWaifuPower(maxWaifuPower);
        }
    }, [element])




    return (
        <div onClick={() => { setCardSelect(cardSelect === 'SELECT' ? 'READY' : 'SELECT'); setSelect('SELECT'); onClickSelectedWaifu(element.id) }} className={`w-36 m-2 h-[185px] rounded-b-lg cursor-pointer ease-in-out duration-300 overflow-hidden bg-white shadow-xl ${cardSelect === 'READY' && "border-2 grayscale-0 border-[#61c739]"}`}>
            <div className="h-32 relative" style={{ backgroundImage: `url(${bg_time})`, backgroundSize: 'cover', backgroundPosition: 'bottom' }}>
                {licenceTime && <div className="absolute flex flex-row top-1 left-1 px-1 border-2 rounded-full text-[11px] border-[#006672] text-[#006672] bg-[#ABEEFC]"><img className="w-4 mr-2" src={license_icon} alt="license icon" /> {licenceTime}</div>}

                <img alt="start-img" src={startArray[startValue(element?.waifuPower) || 0]} className={`w-6 absolute top-2 right-2 `} />
                <GetImageAdn genData={element?.adn || "0003000300300030030003000300030030003003000300300030030030303"} />
                <div className="absolute bottom-2 text-xs right-2 p-1 border-2 rounded-xl border-[#DB1A99] bg-[#F0C1DC] z-[26]">{element.durability > 100000 ? "Infinite" : element.durability}</div>
            </div>
            <div className="text-sm font-semibold text-center">
                <p className=" text-md flex flex-row justify-center items-center"> <strong> {waifuPower}  WP </strong>{waifuIcon && <img src={waifuIcon} alt="material" className="w-8 p-1" />} </p>
            </div>
            <div className={`relative bg-backgroundOp text-center px-1 rounded-b-lg`}>
                <button className={`rounded-lg text-white text-xs px-4 ${cardSelect === "READY" ? "bg-[#61c739]" : "bg-[#D765A5]"}`}> <strong>SELECT</strong>   </button>
            </div>
        </div>
    )
}