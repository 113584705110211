import React from "react";

import dawn_w from "../../assets/img/waifuBackground/dawn.webp";
import day_w from "../../assets/img/waifuBackground/day.webp";
import afternoon_w from "../../assets/img/waifuBackground/afternoon.webp";
import night_w from "../../assets/img/waifuBackground/night.webp";
import evening_w from "../../assets/img/waifuBackground/evening.webp";

import day_t from "../../assets/img/backgroundTractor/day.webp";
import afternoon_t from "../../assets/img/backgroundTractor/afternoon.webp";
import night_t from "../../assets/img/backgroundTractor/night.webp";
import dawn_t from "../../assets/img/backgroundTractor/dawn.webp";
import evening_t from "../../assets/img/backgroundTractor/evening.webp";

const Waifu = () => {
    let hour = (new Date()).getHours();
    if (hour < 4) return night_w;
    if (hour < 8) return dawn_w;
    if (hour < 16) return day_w;
    if (hour < 18) return afternoon_w;
    if (hour < 20) return evening_w;
    if (hour <= 24) return night_w;
}

const Tractor = () => {
    let hour = (new Date()).getHours();
    if (hour < 4) return night_t;
    if (hour < 8) return dawn_t;
    if (hour < 16) return day_t;
    if (hour < 18) return afternoon_t;
    if (hour < 20) return evening_t;
    if (hour <= 24) return night_t;
}

export default {Waifu, Tractor};