import React from 'react';
import cottonIcon from "../../assets/img/claimIcons/cottonIcon.svg"
export default function HarvestingItem({ setModal }) {
    return <div className=" shadow-md w-72 rounded-lg relative mt-5 mx-2 cursor-pointer bg-backgroundOp" >

        <div className="w-full bg-fixed relative h-48 text-center px-3">

            <p className="text-2xl"> <strong>Cotton</strong>  </p>
            <img className="rounded-lg mb-1" src={cottonIcon} alt="cotton" />

        </div>
        <div className="bg-background text-center py-3 rounded-b-lg h-48 px-9">
            <div className="flex justify-between pb-3"> <p className="text-xl"> <strong>Waifu Power</strong>  </p> <p className="text-xl"> <strong>100 WP</strong>  </p>  </div>
            <div className="flex justify-between pb-3"> <p className="text-xl"> <strong>Win rate</strong>  </p> <p className="text-xl"> <strong>90%</strong>  </p>  </div>
            <div className="flex justify-between pb-3"> <p className="text-xl"> <strong>Material</strong>  </p> <p className="text-xl"> <strong>1</strong>  </p>  </div>
            <div className="flex justify-center pb-3"> <button onClick={() => setModal(true)} className="font-bold bg-gradient-to-b  shadow-buttonpressed from-primaryskyblue py-2 px-4 to-primarypeach rounded-full border border-bordergray  transition duration-100 transform hover:scale-105 active:scale-95"> HARVEST </button> </div>

        </div>
    </div>
}
